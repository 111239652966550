<template>
  <div
    :class="{
      'has-error': hasError,
      'is-disabled': disabled
    }"
    @click="clickHandler()"
    class="control checkbox checkbox-control">
      <input
        type="checkbox"
        v-model="inputValue"
        :true-value="true"
        :false-value="false" /><slot></slot>
  </div>
</template>

<script>
export default {
  name: 'checkbox-control',
  props: {
    value: {
      type: Boolean,
      default: false
    },

    /**
     * optional store action/mutation on value change
     * { action: foo, mutation: bar [, more: callback-params]}
     * otherwise value will be emitted via v-model
     */ 
    callback: {
      type: Object
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: false
    }
  },
  computed: {
    hasError () {
      return this.error
    }
  },
  methods: {
    clickHandler (Event) {
      if (!this.disabled) {
        this.toggle()
        this.publish(fn.isTrue(this.inputValue))
      }
    },
    toggle () {
      this.inputValue = !this.inputValue
    },
    publish (value) {
      if (fn.isObject(this.callback)) {
        var params = fn.assign({
          value: value,
          event: 'change'
          }, this.callback
        )
        if(fn.isString(this.callback.action)) {
          this.$store.dispatch(this.callback.action, params)
        } else if(fn.isString(this.callback.mutation)) {
          this.$store.commit(this.callback.mutation, params)
        } else {
          info('wrong parameter for callback in checkbox control')
        }
      } else {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value !== this.inputValue) {
          this.inputValue = value
        }
      }
    }
  }
}
</script>

<style lang="sass">
.checkbox-control
</style>