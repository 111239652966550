<template>
  <div class="configurator-step-4">
    <row-elem class="headline">
      <col-elem>
        <span class="headline-step">{{ $store.state.page.content.headlinestep }}</span>
        <h2 class="typo">
          <span class="prefix">04</span>{{ content.headline }}
        </h2>
      </col-elem>
    </row-elem>
    <row-elem class="control-row is-multiline">
      <col-elem v-if="hasField('climateClass')" :class="[ classCol('climateClass') ]">
        <label-control
          :help="content.climateclasshelp">
            {{ content.climateclasslabel }}
        </label-control>
        <options-control
          v-model="$store.state.project.fields.climateClass.value"
          :options="$store.state.project.fields.climateClass.options"
          :disabled="$store.state.project.fields.climateClass.disabled"
          :error="$store.state.project.fields.climateClass.error"
          :callback="{ action: 'project/setClimateClass' }"
          :lang="content.climateclassoptions"
          class="climate-class-control" />
      </col-elem>
      <col-elem v-if="hasField('ballustrade')" :class="[ classCol('ballustrade') ]">
        <label-control
          :help="content.ballustradehelp">
            {{ content.ballustradelabel }}
        </label-control>
        <options-control
          v-model="$store.state.project.fields.ballustrade.value"
          :options="$store.state.project.fields.ballustrade.options"
          :disabled="$store.state.project.fields.ballustrade.disabled"
          :error="$store.state.project.fields.ballustrade.error"
          :callback="{ action: 'project/setBallustrade' }"
          :lang="content.ballustradeoptions"
          class="ballustrade-control" />
      </col-elem>
      <col-elem v-if="hasField('supportType')" :class="[ classCol('supportType') ]">
        <label-control
          :help="content.supporttypehelp">
            {{ content.supporttypelabel }}
        </label-control>
        <options-control
          v-model="$store.state.project.fields.supportType.value"
          :options="$store.state.project.fields.supportType.options"
          :disabled="$store.state.project.fields.supportType.disabled"
          :error="$store.state.project.fields.supportType.error"
          :callback="{ action: 'project/setSupportType' }"
          :lang="content.supporttypeoptions"
          class="support-type-control" />
      </col-elem>
      <col-elem v-if="hasField('frequency')" :class="[ classCol('frequency') ]">
        <label-control
          :unit="content.frequencyunit"
          :help="content.frequencyhelp">
            {{ content.frequencylabel }}
        </label-control>
        <options-control
          v-model="$store.state.project.fields.frequency.value"
          :options="$store.state.project.fields.frequency.options"
          :disabled="$store.state.project.fields.frequency.disabled"
          :error="$store.state.project.fields.frequency.error"
          :callback="{ action: 'project/setFrequency' }"
          class="frequency-control" />
      </col-elem>
      <col-elem v-if="hasField('powerSupply')" :class="[ classCol('powerSupply') ]">
        <label-control
          :help="content.powersupplyhelp">
            {{ content.powersupplylabel }}
        </label-control>
        <select-control
          v-model="$store.state.project.fields.powerSupply.value"
          :options="$store.state.project.fields.powerSupply.options"
          :disabled="$store.state.project.fields.powerSupply.disabled"
          :error="$store.state.project.fields.powerSupply.error"
          :callback="{ action: 'project/setPowerSupply' }"
          :changed="$store.state.project.fields.powerSupply.changed"
          :lang="content.powersupplyoptions"
          class="power-supply-control" />
      </col-elem>
    </row-elem>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectControl from '@/components/controls/Select'
import OptionsControl from '@/components/controls/Options'
import LabelControl from '@/components/controls/Label'

export default {
  name: 'configurator-step-4',
  components: {
    SelectControl,
    OptionsControl,
    LabelControl
  },
  props: {
    content: {}
  },
  data () {
    return {
      cols: {
        ballustrade:  { default: 'is-6' },
        supportType:  { default: 'is-6' },
        powerSupply:  { default: 'is-12' },
        frequency:    { default: 'is-6' },
        climateClass: { default: 'is-6' }
      }
    }
  },
  computed: {
    ...mapGetters({
      product: 'project/product',
      hasSecondFloor: 'project/hasSecondFloor',
      isMetric: 'project/isMetric',
      isImperial: 'project/isImperial',
      hasField: 'project/hasField',
    }),
  },
  methods: {
    classCol (field) {
      if (fn.has(this.cols[field], this.product)) {
        return this.cols[field][this.product]
      } else {
        return this.cols[field].default
      }
    }
  }
}
</script>

<style lang="sass">
.configurator-step-4
</style>
