<template>
  <div class="configurator-step-5">
    <row-elem class="headline">
      <col-elem>
        <span class="headline-step">_</span>
        <h2 class="typo">
          <span class="prefix">{{content.headlineprefix}}</span>{{ content.headline }}
        </h2>
      </col-elem>
    </row-elem>
    <div v-for="(floor, key) in $store.state.project.result" :key="key">
      <row-elem v-if="hasSecondFloor" class="subhead">
        <col-elem>
          <h2 v-if="key === 0" class="typo">{{ content.lowerlevel }}<span> {{ content.groundfloor }}</span></h2>
          <h2 v-else class="typo">{{ content.upperlevel }}</h2>
        </col-elem>
      </row-elem>
      <row-elem class="no-gap is-first">
        <col-elem>
          <div class="sketch sideview">
            <label-control>
              {{ content.sketchsideview }}
            </label-control>
          </div>
        </col-elem>
      </row-elem>
      <row-elem class="no-gap is-last">
        <col-elem class="is-7">
          <div class="sketch transportation">
            <label-control>
              {{ content.sketchtransportation }}
            </label-control>
          </div>
        </col-elem>
        <col-elem class="is-5">
          <div class="sketch frontview">
            <label-control>
              {{ content.sketchfrontview }}
            </label-control>
          </div>
        </col-elem>
      </row-elem>
      <row-elem>
        <col-elem>
          <div class="sketch support">
            <label-control>
              {{ content.sketchsupport }}
            </label-control>
          </div>
        </col-elem>
      </row-elem>
      <row-elem>
        <col-elem>
          <div class="sketch energyefficiency">
            <label-control
              :help="content.energyefficiencyhelp">
                {{ content.energyefficiencylabel }}
            </label-control>
            <div class="ee-classes">
              <div
                v-for="(eeClass, index) in eeClasses"
                :key="index"
                :class="activeClass(key, index)"
                class="ee-class">
                  {{ eeClass }}
              </div>
            </div>
          </div>
        </col-elem>
      </row-elem>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LabelControl from '@/components/controls/Label'

export default {
  name: 'configurator-step-5',
  components: {
    LabelControl
  },
  props: {
    content: {}
  },
  data () {
    return {
      eeClasses: [ 'A+++' , 'A++' , 'A+' , 'A' , 'B' , 'C' , 'D' , 'E']
    }
  },
  computed: {
    ...mapGetters({
      hasSecondFloor: 'project/hasSecondFloor'
    })
  },
  methods: {
    activeClass (floor, index) {
      return this.$store.state.project.result[floor].energyEfficiency.charAt(index) === 'x'? 'is-active' : ''
    }
  }
}
</script>

<style lang="sass">
.configurator-step-5
  .headline-step
    opacity: 0
  .sketch
    padding: m(2) m(3) m(3) m(3)
    background-color: white()
  .sideview
  .transportation
  .frontview
  .energyefficiency
    margin-bottom: m(2)
    .ee-classes
      display: flex
      justify-content: space-between
      margin-top: m(1)
      .ee-class
        padding: m(1)
        width: 11%
        +font('tool')
        line-height: 1.4
        text-align: center
        color: $primary-color
        border: 1px solid $primary-color
        &.is-active
          color: white()
          background-color: $primary-color
</style>
