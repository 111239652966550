<template>
  <div v-if="isLoaded">
    <container-elem class="configurator-page">
      <row-elem>
        <col-elem class="is-8 is-9-md is-12-sm">
          <component
            :is="currentStepComponent"
            :content="stepContent"
            :countries="countries"
            class="step" />
          <row-elem v-if="!isResultStep" class="actions">
            <col-elem class="is-4">
              <button-elem
                @click="prevStepHandler()"
                class="is-primary is-tool is-outline"
                :class="{ 'is-disabled': currentStepIntern === 1 || isWaiting }">
                  {{ $store.state.page.content.buttonprev }}
              </button-elem>
            </col-elem>
            <col-elem class="is-4 is-hidden-xs progress">
              <progress-circle-elem
                :current="currentStep"
                :total="totalStepsCount"
                :animate="animateProgress" />
            </col-elem>
            <col-elem class="is-4">
              <button-elem
                v-if="isLastStep"
                @click="nextStepHandler()"
                class="is-primary is-tool"
                :class="{ 'is-disabled': isWaiting }">
                  {{ $store.state.page.content.buttonresult }}
              </button-elem>
              <button-elem
                v-else
                @click="nextStepHandler()"
                class="is-primary is-tool"
                :class="{ 'is-disabled': isWaiting || hasErrorIntern }">
                  {{ $store.state.page.content.buttonnext }}
              </button-elem>
            </col-elem>
          </row-elem>
          <row-elem v-if="isResultStep" class="result">
            <col-elem class="is-4 is-3-md prev">
              <button-elem
                @click="prevStepHandler()"
                class="is-primary is-tool is-outline">
                  {{ $store.state.page.content.buttonprev }}
              </button-elem>
            </col-elem>
            <col-elem class="is-4 is-6-md drawing">
              <button-elem
                @click="sketchHandler()"
                class="is-success is-medium"
                :class="{ 'is-disabled': isWaiting || hasErrorIntern }">
                  {{ $store.state.page.content.buttonsketch }}
              </button-elem>
            </col-elem>
            <col-elem class="is-4 is-3-md startagain">
              <button-elem
                @click="startAgainHandler()"
                class="is-primary is-tool is-outline">
                  {{ $store.state.page.content.buttonstartagain }}
              </button-elem>
            </col-elem>
          </row-elem>
        </col-elem>
        <col-elem class="is-3 is-offset-1 is-no-offset-md is-hidden-sm">
          <inspector-elem
            @click="resultStepHandler()"
            :isResultStep="isResultStep"
            :countries="countries"
            :disabled="isWaiting || hasErrorIntern" />
          <bimobjects-elem />
        </col-elem>
      </row-elem>
      <row-elem class="is-hidden-lg-only is-hidden-md-only">
        <col-elem>
          <bimobjects-elem />
        </col-elem>
      </row-elem>
    </container-elem>
  </div>
</template>

<script>
import API from '@/services/API'
import Store from '@/services/Store'
import Router from '@/services/Router'
import Timer from '@/services/Timer'
import { mapGetters } from 'vuex'
import InspectorElem from '@/components/elements/Inspector'
import BimobjectsElem from '@/components/elements/Bimobjects'
import ConfiguratorStep1 from '@/components/elements/configurator/Step1'
import ConfiguratorStep2 from '@/components/elements/configurator/Step2'
import ConfiguratorStep3 from '@/components/elements/configurator/Step3'
import ConfiguratorStep4 from '@/components/elements/configurator/Step4'
import ConfiguratorStep5 from '@/components/elements/configurator/Step5'
import ProgressCircleElem from '@/components/elements/ProgressCircle'

export default {
  name: 'configurator-page',
  components: {
    InspectorElem,
    BimobjectsElem,
    ConfiguratorStep1,
    ConfiguratorStep2,
    ConfiguratorStep3,
    ConfiguratorStep4,
    ConfiguratorStep5,
    ProgressCircleElem
  },
  data () {
    return {
      isLoaded: false,
      currentStepIntern: 1,
      stepContent: {},
      animateProgress: false,
      hasErrorIntern: false,
      countries: {}
    }
  },
  created () {
    API.countries()
      .then((response) => {
        this.countries = response.data
      })
  },
  computed: {
    ...mapGetters({
      currentStep: 'project/currentStep',
      stepCount: 'project/stepCount',
      currentStepSlug: 'project/currentStepSlug',
      totalStepsCount: 'project/totalStepsCount',
      currentProduct: 'project/product',
      isMetric: 'project/isMetric',
      isImperial: 'project/isImperial',
      hasError: 'project/hasError'
    }),
    currentStepComponent () {
      return 'configurator-step-' + this.currentStepIntern
    },
    isLastStep () {
      return this.currentStepIntern === this.stepCount - 1
    },
    isResultStep () {
      return this.currentStepIntern === this.stepCount
    },
    nextIsResultStep () {
      return (this.currentStepIntern + 1) === this.stepCount
    },
    isWaiting () {
      return this.currentStepIntern !== this.currentStep
    }
  },
  methods: {
    initConfigurator(product) {
      this.$store.dispatch('project/init', product).then(() => {
        this.initStep()
        this.isLoaded = true
      })
    },
    initStep () {
      this.$router.push({ params: { step: this.currentStepSlug }}).catch(() => {})
      this.currentStepIntern = this.currentStep
    },
    initContent () {
      var path = this.$route.fullPath.replace('/' + this.$route.params.product + '/', '/')
      this.stepContent = this.$store.getters.child(path).content
    },
    prevStepHandler () {
      this.animateProgress = true
      this.$store.dispatch('project/prevStep')
        .then(() => {
          window.setTimeout(() => {
            this.animateProgress = false
            this.initStep()
          }, 500)
        })
        .catch(() => {})
    },
    nextStepHandler () {
      if (this.nextIsResultStep) {
        return this.resultStepHandler(Event)
      }
      if (this.isWaiting || this.hasErrorIntern) {
        return
      }
      this.animateProgress = true
      this.$store.dispatch('project/nextStep')
        .then(() => {
          window.setTimeout(() => {
            this.animateProgress = false
            this.initStep()
          }, 500)
        })
        .catch(() => {})
    },
    startAgainHandler () {
      this.initConfigurator(this.currentProduct)
    },
    resultStepHandler () {
      if (this.isWaiting || this.hasErrorIntern) {
        return
      }
      this.$trigger('modal/show', {
        body: this.$store.state.page.content.calculatemessage,
        modal: true,
        spinner: true,
        actions: {
          cancel: {
            label: this.$store.state.page.content.calculatecancelbutton,
            event: 'configurator/calculate-cancel'
          }
        }
      })
      this.$store.dispatch('project/calculateProject')
        .then(() => {
          return this.$store.dispatch('project/nextStep', this.stepCount)
        })
        .then(() => {
          this.initStep()
          this.$trigger('modal/hide')
        })
        .catch((reject) => {
          if (reject.status !== 499) { // cancled
            this.$trigger('modal/show', {
              body: this.$store.state.page.content.calculateerror,
              error: true
            })
          }
        })
    },
    calculateCancel () {
      API.calculate(null, true)
    },
    sketchHandler () {
      if (this.isWaiting || this.hasErrorIntern) {
        return
      }
      this.$trigger('modal/show', {
        body: this.$store.state.page.content.sketchmessage,
        modal: true,
        spinner: true,
        actions: {
          cancel: {
            label: this.$store.state.page.content.sketchcancelbutton,
            event: 'configurator/sketch-cancel'
          }
        }
      })
      var Stopwatch = new Timer()
      this.$store.dispatch('project/configureSketch')
        .then((response) => {
          window.setTimeout(() => {
            window.open(
              response.data.url,
              '_blank'
            )
            this.$trigger('modal/hide')
          }, Stopwatch.getTimeout(3000))
        })
        .catch((reject) => {
          if (reject.status !== 499) { // cancled
            window.setTimeout(() => {
              this.$trigger('modal/show', {
                body: this.$store.state.page.content.sketcherror,
                error: true
              })
            }, Stopwatch.getTimeout(3000))
          }
        })
    },
    sketchCancel () {
       API.sketch(null, true)
    }
  },
  events: {
    'configurator/calculate-cancel': 'calculateCancel',
    'configurator/sketch-cancel': 'sketchCancel'
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
        if (
          !this.$store.state.project.init ||
          !from || // undefined, if home or other page is previous
          to.params.step !== this.currentStepSlug || // back-button
          (to.params.product !== this.$store.state.project.fields.product.value)
        ) {
          this.initConfigurator(to.params.product)
        }
        this.initContent()
      }
    },
    hasError: {
      handler(error) {
        if (error) {
          this.hasErrorIntern = true
        } else {
          window.setTimeout(() => {
            this.hasErrorIntern = false
          }, 1000)
        }
      }
    }
  }
}
</script>

<style lang="sass">
.configurator-page
  padding-top: m(2)
  .step
    .headline
      margin-bottom: m(4)
      .col
        .headline-step
          +font('tool')
        h2
          +font('regular')
          line-height: 1
          .prefix
            padding-right: m(2)
            +font('bold')
    .subhead
      margin-bottom: m(1)
      h2
        span
          margin-left: m(1)
          +font('regular', 'tool')
    .control-row
  .actions
    margin-top: m(3)
    padding-top: m(4)
    border-top: 2px solid grey(6)
    .col
      display: flex
      flex-direction: column
      justify-content: center
    .progress
      text-align: center
      #progress-circle
        width: 30%
    .button
      width: 100%
  .result
    .col
      display: flex
      flex-direction: column
      justify-content: center
      &.drawing
        .button
          padding-top: m(3)
          padding-bottom: m(3)
    .button
      width: 100%

+md
  .configurator-page
    .actions
      margin-top: m(1)

+xs
  .configurator-page
    .result
      .col
        &.prev
          order: 2
        &.drawing
          order: 1
        &.startagain
          order: 3
</style>