<template>
  <div class="configurator-step-1">
    <row-elem class="headline">
      <col-elem>
        <span class="headline-step">{{ $store.state.page.content.headlinestep }}</span>
        <h2 class="typo">
          <span class="prefix">01</span>{{ content.headline }}
        </h2>
      </col-elem>
    </row-elem>
    <row-elem class="control-row is-multiline">
      <col-elem v-if="hasField('country')" :class="[ classCol('country') ]">
        <label-control
          :help="content.countryhelp">
            {{ content.countrylabel }}
          </label-control>
        <select-control
          v-model="$store.state.project.fields.country.value"
          :options="$store.state.project.fields.country.options"
          :disabled="$store.state.project.fields.country.disabled"
          :error="$store.state.project.fields.country.error"
          :callback="{ action: 'project/setCountry' }"
          :changed="$store.state.project.fields.country.changed"
          :lang="countries"
          class="country-control" />
      </col-elem>
      <col-elem v-if="hasField('norm')" :class="[ classCol('norm') ]">
        <label-control
          :help="content.normhelp">
            {{ content.normlabel }}
        </label-control>
        <options-control
          v-model="$store.state.project.fields.norm.value"
          :options="$store.state.project.fields.norm.options"
          :disabled="$store.state.project.fields.norm.disabled"
          :error="$store.state.project.fields.norm.error"
          :callback="{ action: 'project/setNorm' }"
          :lang="content.normoptions"
          class="norm-control" />
      </col-elem>
      <col-elem v-if="hasField('unit')" :class="[ classCol('unit') ]">
        <label-control
          :help="content.unithelp">
            {{ content.unitlabel }}
        </label-control>
        <options-control
          v-model="$store.state.project.fields.unit.value"
          :options="$store.state.project.fields.unit.options"
          :disabled="$store.state.project.fields.unit.disabled"
          :error="$store.state.project.fields.unit.error"
          :callback="{ action: 'project/setUnit' }"
          :lang="content.unitoptions"
          class="unit-control" />
      </col-elem>
    </row-elem>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectControl from '@/components/controls/Select'
import OptionsControl from '@/components/controls/Options'
import LabelControl from '@/components/controls/Label'

export default {
  name: 'configurator-step-1',
  components: {
    SelectControl,
    OptionsControl,
    LabelControl
  },
  props: {
    content: {},
    countries: {}
  },
  data () {
    return {
      cols: {
        country: { default: 'is-12'},
        norm:    { default: 'is-6' },
        unit:    { default: 'is-6' },
      }
    }
  },
  computed: {
    ...mapGetters({
      product: 'project/product',
      hasSecondFloor: 'project/hasSecondFloor',
      isMetric: 'project/isMetric',
      isImperial: 'project/isImperial',
      hasField: 'project/hasField',
    })
  },
  methods: {
    classCol (field) {
      if (fn.has(this.cols[field], this.product)) {
        return this.cols[field][this.product]
      } else {
        return this.cols[field].default
      }
    }
  }
}
</script>

<style lang="sass">
.configurator-step-1
</style>
