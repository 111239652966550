var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.swipeLeftHandler),expression:"swipeLeftHandler",arg:"swipe",modifiers:{"left":true}},{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.swipeRightHandler),expression:"swipeRightHandler",arg:"swipe",modifiers:{"right":true}}],staticClass:"control options options-control",class:{
    'has-error': _vm.hasError,
    'is-disabled': _vm.disabled,
    'has-icons': _vm.hasIcons,
    'has-help': _vm.hasIcons
  }},[_vm._l((_vm.options),function(option,i){return _c('span',{key:i,staticClass:"option",class:{
        'is-selected': _vm.selectedIndex === i,
        'is-disabled': option.disabled
      },style:(_vm.optionWidth),on:{"click":function($event){return _vm.clickHandler($event, option)}}},[(_vm.hasIcons)?[_c('svg-elem',{attrs:{"name":_vm.icon(option)}})]:[_vm._v(" "+_vm._s(_vm.label(option))+" ")]],2)}),(!_vm.hasOptions)?_c('span',{staticClass:"option"}):_vm._e(),(_vm.hasOptions)?_c('span',{staticClass:"slider",class:{
        'is-first': _vm.selectedIndex === 0,
        'is-last' : _vm.selectedIndex === (_vm.options.length - 1)
      },style:([ _vm.optionWidth, _vm.selectedStyle ])}):_vm._e(),(_vm.hasIcons)?_c('span',{staticClass:"help"},_vm._l((_vm.options),function(option,i){return _c('span',{key:i,class:{
          'is-selected': _vm.selectedIndex === i,
          'is-disabled': option.disabled
        },style:(_vm.optionWidth)},[_vm._v(" "+_vm._s(_vm.label(option))+" ")])}),0):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }