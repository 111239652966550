<template>
  <div class="label label-control">
    <slot></slot>
    <span v-if="required" class="required">*</span>
    <span v-if="hasUnit" class="unit">({{ unit }})</span>
    <v-popover v-if="hasInfo" placement="top">
      <button-elem
        icon="info"
        class="is-icon is-primary is-small is-narrow">
      </button-elem>
      <template slot="popover">
        <button-elem class="is-icon is-narrow is-white button-close" icon="close" v-close-popover></button-elem>
        <text-elem :content="this.help"></text-elem>
      </template>
    </v-popover>
  </div>
</template>

<script>
import { VPopover, VClosePopover } from 'v-tooltip'

export default {
  name: 'label-control',
  components: {
    VPopover
  },
  directives: {
    closePopover: VClosePopover
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    unit: {
      type: String,
      default: null
    },
    help: {
      type: Array
    }
  },
  data () {
    return {
      showTooltip: false
    }
  },
  computed: {
    hasUnit () {
      return fn.isString(this.unit)
    },
    hasInfo () {
      return fn.isArray(this.help) && this.help.length > 0
    }
  }
}
</script>

<style lang="sass">
.label-control
  display: flex
  align-items: baseline
  //margin-bottom: m(1)
  +font('tool', 'semibold')
  +ellipsis
  .required
    padding-left: 2px
    color: $warning-color
  .unit
    padding-left: m(1)
    +font('regular', 'small')
  .button-elem
    margin-left: m(1)
    transform: translateY(1px)
</style>