const timerService = class {
  constructor () {
    this.timeStart = null
    this.timeIntermediate = null
    this.timeStop = null
    this.start()
  }

  /**
   * starts the timer
   */
  start () {
    this.timeStart = new Date().getTime()
  }

  /**
   * stops the timer
   */
  stop () {
    this.timeStop = new Date().getTime()
  }

  /**
   * get intermediate time in ms while timer is going on
   * @return {integer}
   */
  getIntermediate () {
    var timeIntermediate = new Date().getTime()
    return (this.timeIntermediate - this.timeStart)
  }

  /**
   * stops the timer and returnes the time in ms
   * @return {integer}
   */
  getDuration () {
    if (this.timeStop === null) {
      this.stop()
    }
    return (this.timeStop - this.timeStart)
  }

  /**
   * gets the difference to a given timeout in ms, if > 0
   * @param {integer} ms, milliseconds
   * @return {integer}
   */
  getTimeout (ms) {
    var res = ms - this.getDuration()
    return ms > 0 ? ms : 0
  }
}

export default timerService