<template>
  <div
    :class="{
      'has-error': hasError,
      'is-disabled': disabled,
      'has-help': hasHelp,
      'is-blinking': isBlinking
    }"
    class="control text number-control">
      <input
        type="text"
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="inputHandler($event)"
        @change="changeHandler($event)"
        @focus="focusHandler($event)"
        @keydown="keyDownHandler($event)"
        @paste="pasteHandler($event)"
        ref="control" />
      <span v-if="hasHelp" class="help">
        {{ help | replace('{min}', min) | replace('{max}', max) }}
      </span>
  </div>
</template>

<script>
export default {
  name: 'number-control',
  props: {
    value: {
      type: Number,
      default: null
    },

    /**
     * optional store action/mutation on value change
     * { action: foo, mutation: bar [, more: callback-params]}
     * otherwise value will be emitted via v-model
     */ 
    callback: {
      type: Object
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: null
    },
    autocorrect: { // autocorrect to min/max on blur
      type: Boolean,
      default: true
    },
    decimals: { // 0 = integer, * = infinite decimals
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    help: { // can have placeholder {min} and {max}
      type: String,
      default: ''
    },
    changed: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      number: null,
      inputValue: '',
      pristine: true,
      typeReg: null,
      isBlinking: false
    }
  },
  computed: {
    hasError () {
      return this.error || !this.valid(this.inputValue)
    },
    hasHelp () {
      return fn.isString(this.help)
    }
  },
  methods: {
    valid (value) {
      return (fn.isEmpty(value) && this.pristine) || fn.isNumber(this.unformat(value), this.min, this.max)
    },
    format (number) {
      return fn.numberToString(number, this.decimals, true)
    },
    blink () {
      this.isBlinking = true
      window.setTimeout(() => {
        this.isBlinking = false
      }, 300)
    },
    unformat (input) {
      var number = fn.stringToNumber(input, true)
      if (number === 0) {
        return 0
      }
      return number || ''
    },
    typecheck (Event, data) {
      if (fn.getFunctionKey(Event)) {
        return true
      }
      var val = Event.target.value
      val = val.substr(0, Event.target.selectionStart) + data + val.substr(Event.target.selectionEnd)
      return this.typeReg.test(val)
    },
    focusHandler (Event) {
      this.$refs.control.select()
    },
    inputHandler (Event) {
      if (!this.disabled) {
        this.number = this.unformat(this.inputValue)
        this.publish(fn.isEmpty(this.number) ? null : this.number, 'input')
      }
    },
    changeHandler (Event) {
      if (!this.disabled) {
        if (this.autocorrect) {
          if (fn.isNumber(this.max) && this.number > this.max) {
            this.number = this.max
            this.inputValue = this.format(this.number)
            this.blink()
          } else if (fn.isNumber(this.min) && this.number < this.min) {
            this.number = this.min
            this.inputValue = this.format(this.number)
            this.blink()
          }
        }
        if (this.valid(this.inputValue)) {
          this.inputValue = this.format(this.number)
          this.publish(this.number, 'change')
        }
      }
    },
    keyDownHandler (Event) {
      if (!this.typecheck(Event, Event.key)) {
        event.preventDefault()
      } else {
        this.pristine = false
      }
    },
    pasteHandler (Event) {
      var clipboardData = Event.clipboardData || window.clipboardData
      var pastedData = clipboardData.getData('Text')
      if (!this.typecheck(Event, pastedData)) {
        event.preventDefault()
      } else {
        this.pristine = false
      }
    },
    publish (value, event) {
      if (fn.isObject(this.callback)) {
        var params = fn.assign({
          value: value,
          event: event
          }, this.callback
        )
        if(fn.isString(this.callback.action)) {
          this.$store.dispatch(this.callback.action, params)
        } else if(fn.isString(this.callback.mutation)) {
          this.$store.commit(this.callback.mutation, params)
        } else {
          info('wrong parameter for callback in number control')
        }
      } else {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newNum) {
        if (newNum !== this.number) {
          this.number = fn.isNumber(newNum) ? newNum : null
          this.inputValue = this.format(newNum)
        }
      }
    },
    decimals: {
      immediate: true,
      handler(decimals) {
        this.typeReg = fn.numberRegExp(decimals, true)
      }
    },
    changed: {
      handler(count) {
        this.blink()
      }
    }
  }
}
</script>

<style lang="sass">
.number-control
  input
    text-align: center
</style>