<template>
  <div class="progress-circle-elem" :class="[ 'is-' + this.current, animationClass ]">
    <span class="path"></span>
    <span class="slice">
      <span class="fill"></span>
      <span class="bar"></span>
    </span>
    <span class="dot"></span>
    <span class="bar-dot">
      <span class="dot"></span>
    </span>
    <span class="steps">
      <span class="current">0{{ currentIntern }}</span>
      <span class="divider">/</span>
      <span class="total">0{{ total }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'progress-circle-elem',
  props: {
    current: {
      type: Number
    },
    total: {
      type: Number
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentIntern: 1
    }
  },
  computed: {
    animationClass () {
      return this.animate ? 'is-animated' : ''
    }
  },
  methods: {
    setCurrent(value, delay) {
      if (delay) {
        window.setTimeout(() => {
          this.currentIntern = value > this.total ? this.total : value
        }, 500)
      } else {
        this.currentIntern = value > this.total ? this.total : value
      }
    }
  },
  watch: {
    current: {
      immediate: true,
      handler(newVal, oldVal) {
        this.setCurrent(newVal, newVal > oldVal)
      }
    }
  }
}
</script>

<style lang="sass">
.progress-circle-elem
  position: relative
  font-size: 120px
  width: 1em
  height: 1em
  border-radius: 50%
  margin: 0 auto
  z-index: $z-index-base
  *
    position: absolute
    top: 0
    left: 0
  .path
    top: .03em
    left: .03em
    width: .94em
    height: .94em
    border: .06em solid grey(6)
    border-radius: 50%
  .slice
    width: 1em
    height: 1em
    clip: rect(0em, 1em, 1em, 0.5em)
  .bar
    width: 1em
    height: 1em
    clip: rect(0em, 0.5em, 1em, 0em)
    border: 0.12em solid $primary-color
    border-radius: 50%
    transform: rotate(0deg)
  .fill
    width: 1em
    height: 1em
    clip: rect(0em, 1em, 1em, 0.5em)
    border: 0.12em solid $primary-color
    border-radius: 50%
    opacity: 0
  .bar-dot
    width: 1em
    height: 1em
    transform: rotate(0deg)
  .dot
    width: 0.12em
    height: 0.12em
    left: 50%
    transform: translateX(-50%)
    border-radius: 50%
    background-color: $primary-color
    z-index: $z-index-base + 1
  .steps
    display: flex
    justify-content: center
    width: .6em
    height: .6em
    top: 0.2em
    left: 0.2em
    .current
      position: relative
      align-self: flex-start
      +font('bold', 'big')
      transform: translateX(0.1em)
    .divider
      position: relative
      align-self: center
      +font('semibold')
      font-size: .6em
      color: $primary-color
    .total
      position: relative
      align-self: flex-end
      +font('default')
      transform: translateX(-0.4em)
  &.is-1
    .bar,
    .bar-dot
      transform: rotate(90deg)
  &.is-2
    .bar,
    .bar-dot
      transform: rotate(180deg)
  &.is-3
    .slice
      clip: rect(0em, 1em, 1em, 0em)
    .bar,
    .bar-dot
      transform: rotate(270deg)
    .fill
      opacity: 1
  &.is-4,
  &.is-5
    .slice
      clip: rect(0em, 1em, 1em, 0em)
    .bar,
    .bar-dot
      transform: rotate(360deg)
    .fill
      opacity: 1
  &.is-animated
    .slice
      transition: clip 0s
      transition-delay: .5s
    .bar
      transition: all .5s ease
    .fill
      transition: opacity 0s
      transition-delay: .5s
    .bar-dot
      transition: all .5s ease
    &.is-3
      .slice
        transition: none
      .fill
        transition: none
</style>