<template>
  <div class="configurator-step-2">
    <row-elem class="headline">
      <col-elem>
        <span class="headline-step">{{ $store.state.page.content.headlinestep }}</span>
        <h2 class="typo">
          <span class="prefix">02</span>{{ content.headline }}
        </h2>
      </col-elem>
    </row-elem>
    <row-elem class="control-row is-multiline">
      <col-elem v-if="hasField('floorsCount')" :class="[ classCol('floorsCount') ]">
        <label-control
          :help="content.floorscounthelp">
            {{ content.floorscountlabel }}
        </label-control>
        <options-control
          v-model="$store.state.project.fields.floorsCount.value"
          :options="$store.state.project.fields.floorsCount.options"
          :disabled="$store.state.project.fields.floorsCount.disabled"
          :error="$store.state.project.fields.floorsCount.error"
          :callback="{ action: 'project/setFloorsCount' }"
          class="floors-count-control" />
        <checkbox-control
          v-model="$store.state.project.fields.pit.value"
          :disabled="$store.state.project.fields.pit.disabled"
          :error="$store.state.project.fields.pit.error"
          :callback="{ action: 'project/setPit' }"
          class="pit-control">{{ content.pitlabel }}</checkbox-control>
      </col-elem>
      <col-elem v-if="hasField('unitsCount')" :class="[ classCol('unitsCount') ]">
        <label-control
          :help="content.unitscounthelp">
            {{ content.unitscountlabel }}
        </label-control>
        <options-control
          v-model="$store.state.project.fields.unitsCount.value"
          :options="$store.state.project.fields.unitsCount.options"
          :disabled="$store.state.project.fields.unitsCount.disabled"
          :error="$store.state.project.fields.unitsCount.error"
          :callback="{ action: 'project/setUnitsCount' }"
          class="units-count-control" />
      </col-elem>
      <col-elem v-if="hasField('unitsDistance')" :class="[ classCol('unitsDistance') ]">
        <label-control
          :unit="unitsDistanceUnit"
          :help="content.unitsdistancehelp">
            {{ content.unitsdistancelabel }}
        </label-control>
        <number-control 
          v-model="$store.state.project.fields.unitsDistance.value"
          :min="$store.state.project.fields.unitsDistance.min"
          :max="$store.state.project.fields.unitsDistance.max"
          :disabled="$store.state.project.fields.unitsDistance.disabled"
          :error="$store.state.project.fields.unitsDistance.error"
          :help="unitsDistanceHint"
          :callback="{ action: 'project/setUnitsDistance' }"
          class="units-distance-control" />
      </col-elem>
      <col-elem v-if="hasField('arrangement')" :class="[ classCol('arrangement') ]">
        <label-control
          :help="content.arrangementhelp">
            {{ content.arrangementlabel }}
        </label-control>
        <options-control
          v-model="$store.state.project.fields.arrangement.value"
          :options="$store.state.project.fields.arrangement.options"
          :icons="arrangementIcons"
          :disabled="$store.state.project.fields.arrangement.disabled"
          :error="$store.state.project.fields.arrangement.error"
          :callback="{ action: 'project/setArrangement' }"
          :lang="content.arrangementoptions"
          class="arrangement-control" />
      </col-elem>
    </row-elem>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NumberControl from '@/components/controls/Number'
import OptionsControl from '@/components/controls/Options'
import CheckboxControl from '@/components/controls/Checkbox'
import LabelControl from '@/components/controls/Label'

export default {
  name: 'configurator-step-2',
  components: {
    NumberControl,
    OptionsControl,
    CheckboxControl,
    LabelControl
  },
  props: {
    content: {}
  },
  data () {
    return {
      cols: {
        floorsCount:   { default: 'is-4', iwalk: 'is-6' },
        unitsCount:    { default: 'is-4', iwalk: 'is-6' },
        unitsDistance: { default: 'is-4' },
        arrangement:   { default: 'is-12' }
      }
    }
  },
  computed: {
    ...mapGetters({
      product: 'project/product',
      hasSecondFloor: 'project/hasSecondFloor',
      isMetric: 'project/isMetric',
      isImperial: 'project/isImperial',
      hasField: 'project/hasField',
    }),
    unitsDistanceUnit () {
      return this.isImperial ? this.content.unitsdistanceunitimperial : this.content.unitsdistanceunitmetric
    },
    unitsDistanceHint () {
      if (!fn.isString(this.content.unitsdistancehint)) {
        return ''
      }
      return this.content.unitsdistancehint
        .replace('{min}', '{min} ' + this.unitsDistanceUnit )
        .replace('{max}', '{max} ' + this.unitsDistanceUnit )
    },
    arrangementIcons () {

      // option: font-icon name
      return {
        single: 'sprite-single',
        parallel: 'sprite-parallel',
        crisscross: 'sprite-crisscross',
        opencrisscross: 'sprite-opencrisscross'
      }
    }
  },
  methods: {
    classCol (field) {
      if (fn.has(this.cols[field], this.product)) {
        return this.cols[field][this.product]
      } else {
        return this.cols[field].default
      }
    }
  }
}
</script>

<style lang="sass">
.configurator-step-2
  .floors-count-control
    margin-bottom: 0
  .pit-control
    +font('default')
    align-items: center
</style>
