<template>
  <div class="configurator-step-3">
    <row-elem class="headline">
      <col-elem>
        <span class="headline-step">{{ $store.state.page.content.headlinestep }}</span>
        <h2 class="typo">
          <span class="prefix">03</span>{{ content.headline }}
        </h2>
      </col-elem>
    </row-elem>
    <div v-for="(floor, key) in $store.state.project.fields.floors" :key="key">
      <row-elem v-if="hasSecondFloor" class="subhead">
        <col-elem>
          <h2 v-if="key === 0" class="typo">{{ content.lowerlevel }}<span> {{ content.groundfloor }}</span></h2>
          <h2 v-else class="typo">{{ content.upperlevel }}</h2>
        </col-elem>
      </row-elem>
      <row-elem class="control-row is-multiline">
        <col-elem v-if="hasField('rise')" :class="[ classCol('rise') ]">
          <label-control
            :unit="riseUnit"
            :help="content.risehelp">
              {{ content.riselabel }}
          </label-control>
          <number-control 
            v-model="floor.rise.value"
            :min="floor.rise.min"
            :max="floor.rise.max"
            :decimals="floor.rise.decimals"
            :disabled="floor.rise.disabled"
            :error="floor.rise.error"
            :help="riseHint"
            :changed="floor.rise.changed"
            :callback="{
              action: 'project/setRise',
              floor: key
            }"
            class="rise-control" />
        </col-elem>
        <col-elem v-if="hasField('length')" :class="[ classCol('length') ]">
          <label-control
            :unit="lengthUnit"
            :help="content.lengthhelp">
              {{ content.lengthlabel }}
          </label-control>
          <number-control 
            v-model="floor.length.value"
            :min="floor.length.min"
            :max="floor.length.max"
            :decimals="floor.length.decimals"
            :disabled="floor.length.disabled"
            :error="floor.length.error"
            :help="lengthHint"
            :changed="floor.length.changed"
            :callback="{
              action: 'project/setLength',
              floor: key
            }"
            class="length-control" />
        </col-elem>
        <col-elem v-if="hasField('inclination')" :class="[ classCol('inclination') ]">
          <label-control
            :unit="content.inclinationunit"
            :help="content.inclinationhelp">
              {{ content.inclinationlabel }}
          </label-control>
          <options-control
            v-model="floor.inclination.value"
            :options="floor.inclination.options"
            :disabled="floor.inclination.disabled"
            :error="floor.inclination.error"
            :callback="{
              action: 'project/setInclination',
              floor: key
            }"
            :lang="inclinationOptionsLang"
            class="inclination-control" />
        </col-elem>
        <col-elem v-if="hasField('stepWidth')" :class="[ classCol('stepWidth') ]">
          <label-control
            :unit="stepWidthUnit"
            :help="content.stepwidthhelp">
              {{ content.stepwidthlabel }}
          </label-control>
          <options-control
            v-model="floor.stepWidth.value"
            :options="floor.stepWidth.options"
            :disabled="floor.stepWidth.disabled"
            :error="floor.stepWidth.error"
            :callback="{
              action: 'project/setStepWidth',
              floor: key
            }"
            :lang="stepWidthOptionsLang"
            class="step-width-control" />
        </col-elem>
        <col-elem v-if="hasField('speed')" :class="[ classCol('speed') ]">
          <label-control
            :unit="speedUnit"
            :help="content.speedhelp">
            {{ content.speedlabel }}
          </label-control>
          <options-control
            v-model="floor.speed.value"
            :options="floor.speed.options"
            :disabled="floor.speed.disabled"
            :error="floor.speed.error"
            :callback="{
              action: 'project/setSpeed',
              floor: key
            }"
            :lang="speedOptionsLang"
            class="speed-control" />
        </col-elem>
        <col-elem v-if="hasField('flatSteps')" :class="[ classCol('flatSteps') ]">
          <label-control
            :help="content.flatstepshelp">
              {{ content.flatstepslabel }}
          </label-control>
          <select-control
            v-model="floor.flatSteps.value"
            :options="floor.flatSteps.options"
            :disabled="floor.flatSteps.disabled"
            :error="floor.flatSteps.error"
            :callback="{
              action: 'project/setFlatSteps',
              floor: key
            }"
            :changed="floor.flatSteps.changed"
            :lang="content.flatstepsoptions"
            class="flat-steps-control" />
        </col-elem>
        <col-elem v-if="hasField('headLength')" :class="[ classCol('headLength') ]">
          <label-control
            :help="content.headlengthhelp">
              {{ content.headlengthlabel }}
          </label-control>
          <select-control
            v-model="floor.headLength.value"
            :options="floor.headLength.options"
            :disabled="floor.headLength.disabled"
            :error="floor.headLength.error"
            :callback="{
              action: 'project/setHeadLength',
              floor: key
            }"
            :changed="floor.headLength.changed"
            :lang="content.headlengthoptions"
            class="head-length-control" />
        </col-elem>
      </row-elem>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectControl from '@/components/controls/Select'
import NumberControl from '@/components/controls/Number'
import OptionsControl from '@/components/controls/Options'
import LabelControl from '@/components/controls/Label'

export default {
  name: 'configurator-step-3',
  components: {
    SelectControl,
    NumberControl,
    OptionsControl,
    LabelControl
  },
  props: {
    content: {}
  },
  data () {
    return {
      cols: {
        rise:        { default: 'is-4', orinocohorizontal: 'is-6' },
        length:      { default: 'is-4', orinocohorizontal: 'is-4', iwalk: 'is-4' },
        inclination: { default: 'is-4', orinocohorizontal: 'is-8' },
        stepWidth:   { default: 'is-4', orinocohorizontal: 'is-7', iwalk: 'is-8' },
        speed:       { default: 'is-5' },
        flatSteps:   { default: 'is-7' },
        headLength:  { default: 'is-7' }
      }
    }
  },
  computed: {
    ...mapGetters({
      product: 'project/product',
      hasSecondFloor: 'project/hasSecondFloor',
      riseMaxTotal: 'project/riseMaxTotal',
      isMetric: 'project/isMetric',
      isImperial: 'project/isImperial',
      hasField: 'project/hasField',
    }),
    riseUnit () {
      return this.isImperial ? this.content.riseunitimperial : this.content.riseunitmetric
    },
    riseHint () {
      if (!fn.isString(this.content.risehinttwolevels)) {
        return ''
      }
      if (this.hasSecondFloor && this.riseMaxTotal) {
        return this.content.risehinttwolevels
          .replace('{min}', '{min} ' + this.riseUnit )
          .replace('{max}', '{max} ' + this.riseUnit )
          .replace('{total}', this.riseMaxTotal + ' ' + this.riseUnit )
      } else {
        return this.content.risehintonelevel
          .replace('{min}', '{min} ' + this.riseUnit )
          .replace('{max}', '{max} ' + this.riseUnit )
      }
    },
    lengthUnit () {
      return this.isImperial ? this.content.lengthunitimperial : this.content.lengthunitmetric
    },
    lengthHint () {
      if (!fn.isString(this.content.lengthhint)) {
        return ''
      }
      return this.content.lengthhint
        .replace('{min}', '{min} ' + this.lengthUnit )
        .replace('{max}', '{max} ' + this.lengthUnit )
    },
    inclinationOptionsLang () {
      var res = {}
      fn.each(this.$store.state.project.fields.floors[0].inclination.options, (option) => {
        res[option.key] = fn.numberToString(option.key, null, true)
      })
      return  res
    },
    stepWidthUnit () {
      return this.isImperial ? this.content.stepwidthunitimperial : this.content.stepwidthunitmetric
    },
    stepWidthOptionsLang () {
      var res = {}
      fn.each(this.content.stepwidthoptions, (option, key) => {

        // no fn.numberToString(), because it's already translated and it's like a "string"
        res[key] = this.isImperial ? option.labelimperial : option.labelmetric
      })
      return  res
    },
    speedUnit () {
      return this.isImperial ? this.content.speedunitimperial : this.content.speedunitmetric
    },
    speedOptionsLang () {
      var res = {}
      fn.each(this.content.speedoptions, (option, key) => {
        res[key] = this.isImperial ? option.labelimperial : option.labelmetric
      })
      return  res
    }
  },
  methods: {
    classCol (field) {
      if (fn.has(this.cols[field], this.product)) {
        return this.cols[field][this.product]
      } else {
        return this.cols[field].default
      }
    }
  }
}
</script>

<style lang="sass">
.configurator-step-3
</style>
