<template>
  <div class="inspector-elem">
    <div class="headline">
      <span class="icon-right"></span>
      <span class="label">{{ $store.state.page.content.inspectorheadline }}</span>
    </div>
    <div class="badge">
      <div class="prop">
        <span class="value">{{ $store.state.site.products[currentProduct] }}</span>
        <span class="label">{{ $store.state.page.content.inspectorproduct }}</span>
      </div>
    </div>
    <div class="badge">
      <div class="prop">
        <span class="value">{{ $store.state.project.fields.floorsCount.value }}</span>
        <span class="label">
          <template v-if="hasSecondFloor">{{ $store.state.page.content.inspectorfloorscount }}</template>
          <template v-else>{{ $store.state.page.content.inspectorfloorcount }}</template>
        </span>
      </div>
      <div class="prop">
        <span class="value">{{ $store.state.project.fields.unitsCount.value }}</span>
        <span class="label">
          <template v-if="hasSecondUnit">{{ $store.state.page.content.inspectorunitscount }}</template>
          <template v-else >{{ $store.state.page.content.inspectorunitcount }}</template>
        </span>
      </div>
    </div>
    <div class="badge">
      <div v-if="hasRise" class="prop">
        <span class="value">{{ $store.state.project.fields.floors[0].rise.confirmed | number($store.state.project.fields.floors[0].rise.decimals, true) }}<span class="unit">{{ riseunit }}</span></span>
        <span class="label">{{ $store.state.page.content.inspectorrise }}<template v-if="hasSecondFloor"> {{ contentStep3.lowerlevel }}</template></span>
      </div>
      <div v-if="hasLength" class="prop">
        <span class="value">{{ $store.state.project.fields.floors[0].length.confirmed | number($store.state.project.fields.floors[0].length.decimals, true) }}<span class="unit">{{ lengthunit }} </span></span>
        <span class="label">{{ $store.state.page.content.inspectorlength }}<template v-if="hasSecondFloor"> {{ contentStep3.lowerlevel }}</template></span>
      </div>
      <div class="prop">
        <span class="value">{{ capacity[0] | number(0, true) }}<span class="unit">/h</span></span>
       <span class="label">{{ $store.state.page.content.inspectorcapacity }}</span>
      </div>
    </div>
    <div v-if="hasSecondFloor" class="badge">
      <div v-if="hasRise" class="prop">
        <span class="value">{{ $store.state.project.fields.floors[1].rise.confirmed | number($store.state.project.fields.floors[1].rise.decimals, true) }}<span class="unit">{{ riseunit }}</span></span>
        <span class="label">{{ $store.state.page.content.inspectorrise }} {{ contentStep3.upperlevel }}</span>
      </div>
      <div v-if="hasLength" class="prop">
        <span class="value">{{ $store.state.project.fields.floors[1].length.confirmed | number($store.state.project.fields.floors[1].length.decimals, true) }}<span class="unit">{{ lengthunit }}</span></span>
        <span class="label">{{ $store.state.page.content.inspectorlength }} {{ contentStep3.upperlevel }}</span>
      </div>
      <div class="prop">
        <span class="value">{{ capacity[1] | number(0, true) }}<span class="unit">/h</span></span>
       <span class="label">{{ $store.state.page.content.inspectorcapacity }}</span>
      </div>
    </div>
    <div class="badge">
      <div class="prop">
        <span class="value">{{ arrangement }}</span>
        <span class="label">{{ $store.state.page.content.inspectorarrangement }}</span>
      </div>
    </div>
    <div class="settings">
      <div class="icon-gear"></div>
      <div class="wrapper">
        <div class="badge">
          <div class="prop">
            <span class="value">{{ country }}</span>
            <span class="label">{{ $store.state.page.content.inspectorcountry }}</span>
          </div>
         </div>
         <div class="badge">
          <div class="prop">
            <span class="value">{{ norm }}</span>
            <span class="label">{{ $store.state.page.content.inspectornorm }}</span>
          </div>
          <div class="prop">
            <span class="value">{{ unit }}</span>
            <span class="label">{{ $store.state.page.content.inspectorunit }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isResultStep" class="action">
      <button-elem
        @click="$emit('click')"
        class="is-secondary is-tool"
        :class="{ 'is-disabled': disabled }">
          {{ $store.state.page.content.buttonresult }}
      </button-elem>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'inspector-elem',
  props: {
    countries: {},
    disabled: {
      type: Boolean,
      default: false
    },
    isResultStep: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      riseIntern: [],
      lengthIntern: [],
      contentStep1: {},
      contentStep2: {},
      contentStep3: {}
    }
  },
  created () {
    var path = this.$route.fullPath.replace('/' + this.$route.params.product + '/', '/')
    path = path.replace(/\/step-[\d]{1,}/, '/step-1')
    this.contentStep1 = this.$store.getters.child(path).content
    path = path.replace(/\/step-[\d]{1,}/, '/step-2')
    this.contentStep2 = this.$store.getters.child(path).content
    path = path.replace(/\/step-[\d]{1,}/, '/step-3')
    this.contentStep3 = this.$store.getters.child(path).content
  },
  computed: {
    ...mapGetters({
      hasSecondFloor: 'project/hasSecondFloor',
      hasSecondUnit: 'project/hasSecondUnit',
      hasRise: 'project/hasRise',
      hasLength: 'project/hasLength',
      currentProduct: 'project/product',
      isMetric: 'project/isMetric',
      isImperial: 'project/isImperial',
      capacity: 'project/capacity'
    }),
    arrangement () {
      var key = this.$store.state.project.fields.arrangement.value
      return this.contentStep2.arrangementoptions[key].label
    },
    norm () {
      var key = this.$store.state.project.fields.norm.value
      return this.contentStep1.normoptions[key].label
    },
    unit () {
      var key = this.$store.state.project.fields.unit.value
      return this.contentStep1.unitoptions[key].label
    },
    country () {
      var key = this.$store.state.project.fields.country.value
      return this.countries[key]
    },
    riseunit () {
      if (this.isImperial) {
        return this.contentStep3.riseunitimperial
      } else {
        return this.contentStep3.riseunitmetric
      }
    },
    lengthunit () {
      if (this.isImperial) {
        return this.contentStep3.lengthunitimperial
      } else {
        return this.contentStep3.lengthunitmetric
      }
    }
  }
}
</script>

<style lang="sass">
.inspector-elem
  margin-top: m(5)
  background-color: $primary-color
  .label
    padding: 0
  .headline
    padding: m(1)
    margin-bottom: m(1)
    color: white()
    background-color: $secondary-color
    .icon-right
      +font('small')
    .label
      +font('medium')
  .badge
    display: flex
    justify-content: space-around
    margin: 2px m(2)
    padding: m(1) 3px
    background-color: white()
    .prop
      display: flex
      flex-direction: column
      align-items: center
      .value
        +font('medium')
        color: $primary-color
        line-height: 1
        .unit
          padding-left: 2px
          +font('regular', 'small')
      .label
        padding-top: m(1)
        +font('tiny')
        text-transform: uppercase
        line-height: 1
  .settings
    display: flex
    padding: m(1) m(2)
    .icon-gear
      flex-shrink: 1
      display: flex
      align-items: flex-start
      margin-top: m(1)
      +font('big')
      color: white()
    .wrapper
      flex-grow: 1
      padding-left: m(1)
      .badge
        justify-content: flex-start
        align-items: center
        flex-shrink: 1
        margin: 0
        padding: m(1) 0 0 0
        background-color: transparent
        .prop
          align-items: flex-start
          margin-right: m(2)
          .value
            +font('default')
            color: white()
          .label
            padding-top: 3px
  .action
    padding: m(1) m(2) m(2) m(2)
    .button
      width: 100%
      
+md
  .inspector-elem
    .headline
      .icon-right
        display: none
    .badge
      .prop
        .value
          +font('tool')
          line-height: 1
    .settings
      .icon-gear
        display: none

</style>
