<template>
  <div
    :class="{
      'has-error': hasError,
      'is-disabled': disabled,
      'has-icons': hasIcons,
      'has-help': hasIcons
    }"
    v-touch:swipe.left="swipeLeftHandler"
    v-touch:swipe.right="swipeRightHandler"
    class="control options options-control">
      <span
        v-for="(option, i) in options"
        :key="i"
        @click="clickHandler($event, option)"
        :style="optionWidth"
        :class="{
          'is-selected': selectedIndex === i,
          'is-disabled': option.disabled
        }"
        class="option">
          <template v-if="hasIcons">
            <svg-elem :name="icon(option)" />
          </template>
          <template v-else>
            {{ label(option) }}
          </template>
      </span>
      <span v-if="!hasOptions" class="option">
        &nbsp;
      </span>
      <span
        v-if="hasOptions"
        :style="[ optionWidth, selectedStyle ]"
        :class="{
          'is-first': selectedIndex === 0,
          'is-last' : selectedIndex === (options.length - 1)
        }"
        class="slider">
      </span>
      <span v-if="hasIcons" class="help">
        <span 
          v-for="(option, i) in options"
          :key="i"
          :style="optionWidth"
          :class="{
            'is-selected': selectedIndex === i,
            'is-disabled': option.disabled
          }">
            {{ label(option) }}
        </span>
      </span>
  </div>
</template>

<script>
export default {
  name: 'options-control',
  props: {
    value: {
      default: ''
    },

    /**
     * optional store action/mutation on value change
     * { action: foo, mutation: bar [, more: callback-params]}
     * otherwise value will be emitted via v-model
     */ 
    callback: {
      type: Object
    },
    options: {
      type: Array
    },
    icons: {
      type: Object
    },
    lang: {
      type: Object // @see method label()
    },
    lookup: { // @see method label()
      type: String,
      default: 'label'
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedIndex: 0
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        if (value === '') {
          value = null
        }
        this.$emit('input', value)
      }
    },
    hasOptions () {
      return this.options.length > 0
    },
    hasIcons () {
      return fn.isObject(this.icons)
    },
    hasError () {
      return this.error
    },
    optionWidth () {
      var res = {
        width: '100%'
      }
      if (this.options.length > 0) {
        res.width = fn.round(100/this.options.length, 3, 'down') + '%'
      }
      return res
    },
    selectedStyle () {
      var res = {
        left: '0%'
      }
      if (this.options.length > 0 && this.selectedIndex > 0) {
        res.left = fn.round((100/this.options.length) * this.selectedIndex, 3, 'down') + '%'
      }
      return res
    }
  },
  methods: {
    
    /**
     * Ways to get the label from:
     * 1. from icons, if given
     * 2. from lang-object: key: { lookup: value }
     * 3. from lang-object: key: value
     * 4. from options-object: [ key, lookup ]
     * 5. key
     */
    label (option) {
      if (fn.has(this.lang, option.key)) {
        return fn.isObject(this.lang[option.key]) ? this.lang[option.key][this.lookup] : this.lang[option.key]
      } else if (fn.has(option, this.lookup)) {
        return option[this.lookup]
      }
      return option.key
    },
    icon (option) {
      if (fn.has(this.icons, option.key)) {
        return this.icons[option.key]
      }
      return null
    },
    clickHandler (Event, option) {
      if (!this.disabled && !option.disabled) {
        this.publish(option.key)
      }
    },
    swipeLeftHandler (Event) {
      var index = this.selectedIndex - 1
      if (!this.disabled && fn.has(this.options, index)) {
        this.publish(this.options[index].key)
      }
    },
    swipeRightHandler (Event) {
      var index = this.selectedIndex + 1
      if (!this.disabled && fn.has(this.options, index)) {
        this.publish(this.options[index].key)
      }
    },
    setSelectedIndex () {
      fn.each(this.options, (option, index) => {
        if (option.key === this.value) {
          this.selectedIndex = index
        }
      })
    },
    publish (value) {
      if (fn.isObject(this.callback)) {
        var params = fn.assign({
          value: value,
          event: 'change'
          }, this.callback
        )
        if(fn.isString(this.callback.action)) {
          this.$store.dispatch(this.callback.action, params)
        } else if(fn.isString(this.callback.mutation)) {
          this.$store.commit(this.callback.mutation, params)
        } else {
          info('wrong parameter for callback in option control')
        }
      } else {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(key) {
        this.setSelectedIndex()
      }
    }
  }
}
</script>

<style lang="sass">
.options-control
</style>