<template>
  <div class="bimobjects-elem">
    <div class="logo">
      <svg-elem name="sprite-bimobjects" />
    </div>
    <div class="copy">
      <text-elem :content="$store.state.page.content.bimtext"></text-elem>
    </div>
    <div class="action">
      <link-elem
        :link="$store.state.page.content.bimlink">
          <button-elem class="is-secondary is-tool">
            {{ $store.state.page.content.bimbutton }}
          </button-elem>
      </link-elem>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'bimobjects-elem'
}
</script>

<style lang="sass">
.bimobjects-elem
  display: flex
  flex-direction: column
  margin-top: m(1)
  background-color: $primary-color
  .logo
    padding: m(2) m(2) m(1) m(2)
    .svg-elem
      width: 60%
  .copy
    padding: 0 m(2) m(2) m(2)
    color: white()
  .action
    padding: 0 m(2) m(2) m(2)
    .button
      width: 100%

+sm
  .bimobjects-elem
    flex-direction: row
    align-items: center
    justify-content: space-around
    margin-top: m(2)
    .logo
      display: none
    .copy
      padding: m(2)
    .action
      padding: m(2)
      .button
        width: auto

+xs
  .bimobjects-elem
    flex-direction: column
    .copy
      text-align: center
    .action
      padding-top: 0
</style>
