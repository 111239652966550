var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"configurator-step-3"},[_c('row-elem',{staticClass:"headline"},[_c('col-elem',[_c('span',{staticClass:"headline-step"},[_vm._v(_vm._s(_vm.$store.state.page.content.headlinestep))]),_c('h2',{staticClass:"typo"},[_c('span',{staticClass:"prefix"},[_vm._v("03")]),_vm._v(_vm._s(_vm.content.headline)+" ")])])],1),_vm._l((_vm.$store.state.project.fields.floors),function(floor,key){return _c('div',{key:key},[(_vm.hasSecondFloor)?_c('row-elem',{staticClass:"subhead"},[_c('col-elem',[(key === 0)?_c('h2',{staticClass:"typo"},[_vm._v(_vm._s(_vm.content.lowerlevel)),_c('span',[_vm._v(" "+_vm._s(_vm.content.groundfloor))])]):_c('h2',{staticClass:"typo"},[_vm._v(_vm._s(_vm.content.upperlevel))])])],1):_vm._e(),_c('row-elem',{staticClass:"control-row is-multiline"},[(_vm.hasField('rise'))?_c('col-elem',{class:[ _vm.classCol('rise') ]},[_c('label-control',{attrs:{"unit":_vm.riseUnit,"help":_vm.content.risehelp}},[_vm._v(" "+_vm._s(_vm.content.riselabel)+" ")]),_c('number-control',{staticClass:"rise-control",attrs:{"min":floor.rise.min,"max":floor.rise.max,"decimals":floor.rise.decimals,"disabled":floor.rise.disabled,"error":floor.rise.error,"help":_vm.riseHint,"changed":floor.rise.changed,"callback":{
            action: 'project/setRise',
            floor: key
          }},model:{value:(floor.rise.value),callback:function ($$v) {_vm.$set(floor.rise, "value", $$v)},expression:"floor.rise.value"}})],1):_vm._e(),(_vm.hasField('length'))?_c('col-elem',{class:[ _vm.classCol('length') ]},[_c('label-control',{attrs:{"unit":_vm.lengthUnit,"help":_vm.content.lengthhelp}},[_vm._v(" "+_vm._s(_vm.content.lengthlabel)+" ")]),_c('number-control',{staticClass:"length-control",attrs:{"min":floor.length.min,"max":floor.length.max,"decimals":floor.length.decimals,"disabled":floor.length.disabled,"error":floor.length.error,"help":_vm.lengthHint,"changed":floor.length.changed,"callback":{
            action: 'project/setLength',
            floor: key
          }},model:{value:(floor.length.value),callback:function ($$v) {_vm.$set(floor.length, "value", $$v)},expression:"floor.length.value"}})],1):_vm._e(),(_vm.hasField('inclination'))?_c('col-elem',{class:[ _vm.classCol('inclination') ]},[_c('label-control',{attrs:{"unit":_vm.content.inclinationunit,"help":_vm.content.inclinationhelp}},[_vm._v(" "+_vm._s(_vm.content.inclinationlabel)+" ")]),_c('options-control',{staticClass:"inclination-control",attrs:{"options":floor.inclination.options,"disabled":floor.inclination.disabled,"error":floor.inclination.error,"callback":{
            action: 'project/setInclination',
            floor: key
          },"lang":_vm.inclinationOptionsLang},model:{value:(floor.inclination.value),callback:function ($$v) {_vm.$set(floor.inclination, "value", $$v)},expression:"floor.inclination.value"}})],1):_vm._e(),(_vm.hasField('stepWidth'))?_c('col-elem',{class:[ _vm.classCol('stepWidth') ]},[_c('label-control',{attrs:{"unit":_vm.stepWidthUnit,"help":_vm.content.stepwidthhelp}},[_vm._v(" "+_vm._s(_vm.content.stepwidthlabel)+" ")]),_c('options-control',{staticClass:"step-width-control",attrs:{"options":floor.stepWidth.options,"disabled":floor.stepWidth.disabled,"error":floor.stepWidth.error,"callback":{
            action: 'project/setStepWidth',
            floor: key
          },"lang":_vm.stepWidthOptionsLang},model:{value:(floor.stepWidth.value),callback:function ($$v) {_vm.$set(floor.stepWidth, "value", $$v)},expression:"floor.stepWidth.value"}})],1):_vm._e(),(_vm.hasField('speed'))?_c('col-elem',{class:[ _vm.classCol('speed') ]},[_c('label-control',{attrs:{"unit":_vm.speedUnit,"help":_vm.content.speedhelp}},[_vm._v(" "+_vm._s(_vm.content.speedlabel)+" ")]),_c('options-control',{staticClass:"speed-control",attrs:{"options":floor.speed.options,"disabled":floor.speed.disabled,"error":floor.speed.error,"callback":{
            action: 'project/setSpeed',
            floor: key
          },"lang":_vm.speedOptionsLang},model:{value:(floor.speed.value),callback:function ($$v) {_vm.$set(floor.speed, "value", $$v)},expression:"floor.speed.value"}})],1):_vm._e(),(_vm.hasField('flatSteps'))?_c('col-elem',{class:[ _vm.classCol('flatSteps') ]},[_c('label-control',{attrs:{"help":_vm.content.flatstepshelp}},[_vm._v(" "+_vm._s(_vm.content.flatstepslabel)+" ")]),_c('select-control',{staticClass:"flat-steps-control",attrs:{"options":floor.flatSteps.options,"disabled":floor.flatSteps.disabled,"error":floor.flatSteps.error,"callback":{
            action: 'project/setFlatSteps',
            floor: key
          },"changed":floor.flatSteps.changed,"lang":_vm.content.flatstepsoptions},model:{value:(floor.flatSteps.value),callback:function ($$v) {_vm.$set(floor.flatSteps, "value", $$v)},expression:"floor.flatSteps.value"}})],1):_vm._e(),(_vm.hasField('headLength'))?_c('col-elem',{class:[ _vm.classCol('headLength') ]},[_c('label-control',{attrs:{"help":_vm.content.headlengthhelp}},[_vm._v(" "+_vm._s(_vm.content.headlengthlabel)+" ")]),_c('select-control',{staticClass:"head-length-control",attrs:{"options":floor.headLength.options,"disabled":floor.headLength.disabled,"error":floor.headLength.error,"callback":{
            action: 'project/setHeadLength',
            floor: key
          },"changed":floor.headLength.changed,"lang":_vm.content.headlengthoptions},model:{value:(floor.headLength.value),callback:function ($$v) {_vm.$set(floor.headLength, "value", $$v)},expression:"floor.headLength.value"}})],1):_vm._e()],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }